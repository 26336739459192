<template>
  <div>
    <div id="password-reset-page">
      <h1 class="h1-title">Password Reset</h1>
      <div class="flex-row margin-bottom">
        <div v-if="!reset_complete" class="flex-center padding-2 shadow rounded-corners">
          <h3 class="h3-title">Reset your password</h3>
          <form @submit.prevent="resetPassword" class="flex-column">
            <label class="margin-top-075 label" for="new_password1">New Password</label>
            <input
              class="text-field margin-top-025 fix-placeholder-color"
              type="password"
              id="new_password1"
              v-model="resetDict.new_password1"
              placeholder="Password"
            />
            <label class="margin-top-075 label" for="new_password2">Confirm New Password</label>
            <input
              class="text-field margin-top-025 fix-placeholder-color"
              type="password"
              id="new_password2"
              v-model="resetDict.new_password2"
              placeholder="Confirm Password"
            />
            <primary-button class="margin-top" type="submit" title="Reset Password" />
          </form>
        </div>
        <div v-else class="flex-center padding-2 shadow rounded-corners">
          <h3 class="h3-title">Password Successfully Reset</h3>
          <primary-button class="margin-top horizontal-flex-center" title="Go to Login" v-on:click="goToLogin" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/Buttons/v2/PrimaryButton.vue";
import { resetPassword } from "@/api/Auth.js";

export default {
  name: "PasswordReset",
  components: {
    PrimaryButton
  },
  data() {
    return {
      resetting: false,
      reset_complete: false,
      resetDict: {
        new_password1: "",
        new_password2: ""
      }
    };
  },
  methods: {
    resetPassword() {
      if (this.resetting) return;
      if (this.new_password1 !== this.new_password2) {
        alert("Passwords do not match");
        return;
      }

      this.resetDict.uid = this.$route.params.uuid;
      this.resetDict.token = this.$route.params.token;

      this.resetting = true;
      resetPassword(this.resetDict)
        .then(response => {
          this.reset_complete = true;
          this.resetting = false;
          console.log(response);
        })
        .catch(error => {
          this.resetting = false;
          alert(JSON.stringify(error.response.data));
        });
    },
    goToLogin() {
      this.$router.push({ name: "Login" });
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#password-reset-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
  display: flex;
  flex-direction: column;
}
</style>
